// Path: src/components/message.js
// Compare this snippet from src/components/message_feed.js:
// // this component serves as the container for the chat messages

import React, { Component } from 'react';
import './styles/message.css';

class Message extends Component {

    render() {

        let position;
        let backgroundColor;
        let color;

        const role = this.props.message_sender_role;
        if (role === "user") {
            position = 'flex-end';
            backgroundColor = '#FF8A79';
            color = "var(--font-color-secondary)";
        }


        return (
            <div className='message' id={this.props.message_id} style={{ alignSelf: position, backgroundColor: backgroundColor, color }}>
                <div className='message-header'>
                    {role == "assistant" ?
                        <img className='message-sender-logo' src={this.props.message_sender_logo}></img>
                        :
                        null}

                    <div >
                        <div className='message-content'>
                            {this.props.message_content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Message;
