import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// change the url of the server depending to the environment
export let serverURL;
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  serverURL = 'http://localhost:8000/';
} else {
  serverURL = 'https://server.faltstein.de/';
}
//http://staging-server-env.eba-etsjmpkb.eu-central-1.elasticbeanstalk.com

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
