import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { useState, useEffect } from 'react';

import logo from '../images/logo.svg'
import profileLogo from '../images/user.svg'
import './NavBar.css';

function Navbar({ page }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                setLoading(false);
                // ...
            } else {
                setUser(null);
                setLoading(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    function logOut(event) {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    /**
     * This function handles the click event on the logo. Depending on the auth status, it will redirect to the dashboard or the main page.
     * 
     * @param {Event} event The click event.
     */

    function handleLogoClick(event) {
        if (user !== null) {
            window.location.href = "/dashboard";
        } else {
            window.location.href = "/";
        }
    }

    return (
        <header className="navbar-header">
            <a onClick={handleLogoClick}><img src={logo} className="navbar-logo" alt="logo" /></a>

            {page === "login" || user !== null || loading ? null :
                <div className="navbar-user-settings">
                    <a href="/login" className='login-btn'>
                        Login
                    </a>
                </div>
            }
            {page !== "login" && user !== null ?
                <div className="navbar-profile-settings">
                    <a href="/profile" className='profile-btn'>
                        <img src={profileLogo} className="navbar-profile-logo" alt="user" />
                    </a>
                    < a href="/" className='logout-btn' onClick={logOut}>
                        Log Out
                    </a>
                </div>
                :
                null
            }
        </header>
    );
}

export default Navbar;