import app from "../initFirebase";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import React, { useState, useEffect } from 'react';
import { serverURL } from '../index'

import './LoginPage.css';
import Navbar from '../components/NavBar';

function LoginPage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {
            window.location.href = "/dashboard";
        }
    });

    function logSubmit(event) {
        event.preventDefault();
        const auth = getAuth();
        const benutzername = event.target.benutzername.value;
        const passwort = event.target.passwort.value;
        // here we use the same login credentials to login to the server
        const url = serverURL + 'api/v1/accounts/login/';
        const body = {
            email: benutzername,
            password: passwort
        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then(response => response.json())
            .then((data) => {
                localStorage.setItem('auth_token', data.token);
                console.log(data);
                const url = serverURL + 'api/v1/accounts/users/me/';
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${data.token}`
                    }
                }).then(response => response.json())
                    .then((data) => {
                        console.log(data);
                        localStorage.setItem('user_id', data["id"]);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    }).then(() => {
                        signInWithEmailAndPassword(auth, benutzername, passwort)
                            .then((userCredential) => {
                                // Signed in 
                                const user = userCredential.user;
                                // Redirect to main page
                                setLoading(true);
                                setError(false);
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                setError(true);
                                setTimeout(() => {
                                    setError(false);
                                }, 4000);
                            });
                        console.log("Redirecting to dashboard");
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            });
    }

    return (
        <div className="App">
            <Navbar page={"login"} />
            <div className="login-container">
                {error ?
                    <div className="login-error-message">
                        <p>Invalid username or password</p>
                    </div>
                    :
                    null}
                {!loading ? <div className="login-form-container">
                    <form id='login-form' className="login-form" method="post" onSubmit={logSubmit}>
                        <input type="text" placeholder="Benutzername" name="benutzername" required />
                        <input type="password" placeholder="Passwort" name="passwort" required />
                        <button type="submit">Login</button>
                    </form>
                </div>
                    :
                    <h3>Logging you in...</h3>
                }
            </div>
        </div>
    );
}

export default LoginPage;
