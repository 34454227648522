import './ProfilePage.css';
import { useState } from 'react';
import { getAuth, onAuthStateChanged, updateProfile, updateEmail } from "firebase/auth";

import Navbar from '../components/NavBar';
import { set } from 'firebase/database';

function MainPage() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [org, setOrg] = useState("");

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setName(user.displayName);
            setEmail(user.email);
            user.getIdTokenResult().then((idTokenResult) => {
                if (idTokenResult.claims.role === "admin") {
                    setOrg("admin");
                } else {
                    const client = idTokenResult.claims.client;
                    setOrg(client);
                }
            });
        }
    });

    function changeProfileName(newName) {
        updateProfile(auth.currentUser, {
            displayName: newName
        }).then(() => {
            // Profile updated!
            // ...
        }).catch((error) => {
            // An error occurred
            // ...
        });
    }

    function changeProfileEmail(newEmail) {
        updateEmail(auth.currentUser, newEmail
        ).then(() => {
            // Email updated!
            // ...
        }
        ).catch((error) => {
            // An error occurred
            // ...
        });
    }



    return (
        <div className="App">
            <Navbar page={"main"} />
            <div className="profile-container">
                <div className='profile-content-container'>
                    <h2>Your Profile</h2>
                    <label>Name:</label>
                    <p id='name-field'>{name}</p>
                    <label>Email:</label>
                    <p>{email}</p>
                    <label>Organisation:</label>
                    <p>{org}</p>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
