import React from 'react';
import './styles/chat_input.css';

const FileAttachment = () => {

    return (
        <div className='chat-input-attachment'>
            <button className='file-attachement'>
                <img className='paper_clip' src='/clip.png'></img>
            </button>
        </div>
    );
}

export default FileAttachment;