import React from 'react';
import './styles/chat_input.css';
import FileAttachment from './file_attachment';
import InputField from './input_field';
import SendButton from './send_btn';
import McOptions from './mc_options';

const ChatInput = ({ setInput, setSendToAPI, options, optionCallback, showInputField, withFileAttachment }) => {


    function inputEventHandler(e) {
        if (e.code === "Enter" && e.target.value !== "") {  //checks whether the pressed key is "Enter"
            const message = { "input": e.target.value, "is_multiple_choice": false };
            setInput(message);
            // reset the input field
            document.getElementById('chat_input').value = '';
            setSendToAPI(true);
        }
    };

    function sendBTNEventHandler(e) {
        const input = document.getElementById('chat_input').value;
        if (input === '') {
            return;
        }
        const message = { "input": input, "is_multiple_choice": false };
        setInput(message);
        // reset the input field
        document.getElementById('chat_input').value = '';
        setSendToAPI(true);
    };


    return (
        <div className='chat-input-container'>
            {options.length > 0 ? <McOptions options={options} optionCallback={optionCallback} /> : null}
            <div className='chat-input'>
                {withFileAttachment ? <FileAttachment /> : null}
                {showInputField ? <>
                    <InputField inputEventHandler={inputEventHandler} />
                    <SendButton sendBTNEventHandler={sendBTNEventHandler} />
                </>
                    : null}
            </div>
        </div>
    );
}

export default ChatInput;