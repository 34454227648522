// this component will consist of a top div that will contain the chat messages, and a bottom div that will contain the input field and the send button

import React, { useState, useEffect } from 'react';
import MessageFeed from '../shared/message_feed/message_feed';
import ChatInput from '../shared/chat_input/chat_input';
import Preview from '../shared/message_feed/preview';

import assistant_logo from '../../images/myndpaar_logo.png';

import './styles/chat_functions.css';

import { getConversation, sendMessage, sendMessageToAPI } from './chat_functions'

/**
 * Represents the chat view component.
 * 
 * 
 * @returns {JSX.Element} The rendered chat view component.
 */
const ChatView = ({ projectID, overrideHeight, feedHeight, withFileAttachment, preview, user }) => {
    const [loading, setLoading] = useState(true);
    /*input represents the state of the user generated input*/
    const [input, setInput] = useState('');
    /*messages is an array containing all messages of the current active thread*/
    const [chatHistory, setChatHistory] = useState({ "messages": [] });
    const [options, setOptions] = useState([]);
    /*shouldCallAPI is used as a flag to determine whether the app should send a message to the api*/
    const [sendToAPI, setSendToAPI] = useState(false);
    const [chatID, setChatID] = useState("null");
    const [username, setUsername] = useState("null");
    const [showInputField, setShowInputField] = useState(false);

    useEffect(() => {
        let userID = JSON.parse(localStorage.getItem("user_id"));
        setUsername(userID)
        getConversation(chatID, setChatID, userID, chatHistory, setChatHistory, setOptions, setShowInputField, setLoading)

    }, [user])


    /**
     * This effect is used to check whether the user has sent a message.
     * 
     */
    useEffect(() => {
        if (sendToAPI) {
            // we send the message here
            //setLoading(true);
            sendMessageToAPI(chatID, username, input, chatHistory, setChatHistory, setChatID, setOptions, setShowInputField, setLoading)
        }
        return () => {
            // Cleanup the side effect#
            setSendToAPI(false);
        }
    }, [sendToAPI, input, chatHistory, chatID, username]);

    function optionCallback(option) {
        const message = { "input": option, "is_multiple_choice": true };
        sendMessageToAPI(chatID, username, message, chatHistory, setChatHistory, setChatID, setOptions, setShowInputField, setLoading)
        return option;
    }

    return (
        <div className='chat-functions' id='chat-functions' style={{ height: overrideHeight }}>
            <MessageFeed chatHistory={chatHistory} loading={loading} preview={preview} />
            <ChatInput setInput={setInput} setSendToAPI={setSendToAPI} options={options} optionCallback={optionCallback} showInputField={showInputField} withFileAttachment={withFileAttachment} />
        </div>
    );
}

export default ChatView;