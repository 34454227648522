import './ProjectPage.css';
import React, { useState, useEffect } from 'react';

import ChatView from '../components/chat/chat_view';

function ProjectPage({ projectID, projectName, projectComments, showDashboard, user }) {

    function handleBackClick(e) {
        document.getElementById("project-content").style.display = 'none';
        showDashboard();
    };

    let projectUI = null
    if (projectID === "0001") {
        projectUI = <ChatView withFileAttachment={false} preview={false} projectID={projectID} user={user} />;
    } else if (projectID === "0002") {
        projectUI = <ChatView withFileAttachment={false} preview={true} projectID={projectID} user={user} />;
    }

    return (
        <div className="project-page-content-container" id='project-content'>
            <div className='project-page-header'>
                <div className='project-page-header-title'>
                    <h2>{projectName}</h2>
                    <p>{projectComments}</p>
                </div>
                <button onClick={handleBackClick}>Back to Dashboard</button>
            </div>
            {projectUI}
        </div>
    );
}

export default ProjectPage;
