// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig;
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    firebaseConfig = {
        apiKey: "AIzaSyDmZ_MD6Gpmsu6EhjCTspYOpv72p_pzuq0",
        authDomain: "http://127.0.0.1:9099",
        databaseURL: "http://127.0.0.1:9000/?ns=faltstein-agency-default-rtdb",
        projectId: "faltstein-agency",
        storageBucket: "faltstein-agency.appspot.com",
        messagingSenderId: "677567848124",
        appId: "1:677567848124:web:968f90cd82a35c3da77ab9",
        measurementId: "G-6R28GDHBWK"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyDmZ_MD6Gpmsu6EhjCTspYOpv72p_pzuq0",
        authDomain: "faltstein-agency.firebaseapp.com",
        databaseURL: "https://faltstein-agency-default-rtdb.europe-west1.firebasedatabase.app/",
        projectId: "faltstein-agency",
        storageBucket: "faltstein-agency.appspot.com",
        messagingSenderId: "677567848124",
        appId: "1:677567848124:web:968f90cd82a35c3da77ab9",
        measurementId: "G-6R28GDHBWK"
    };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
//     connectAuthEmulator(auth, "http://127.0.0.1:9099");
// }

export default app;