import './styles/chat_functions.css';
import { serverURL } from '../..';
/**
 * Containes all functions for the chat view.
 */

/**
 * Retrieves messages for the active thread and updates the messages state.
 * 
 * @param {string} activeThread - The active thread identifier.
 * @param {function} setMessages - The state setter function for messages.
 * @param {string} user_logo - The logo for user messages.
 * @param {string} assistant_logo - The logo for assistant messages.
 * @returns {Promise<void>} - A promise that resolves when the messages are retrieved and parsed.
 */

export function getConversation(chat_id, setChatID, username, chatHistory, setChatHistory, setOptions, setShowInputField, setLoading) {
    const url = serverURL + `api/v2/conversations/?chat_id=${chat_id}&user=${username}`;

    let authtoken_server = localStorage.getItem("auth_token");
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authtoken_server}`
            //'X-CSRFToken': csrfToken
        }
    }).then(response => response.json())
        .then((data) => {
            //reverse the order
            data.messages.sort((a, b) => new Date(a.message.created_at) - new Date(b.message.created_at))
            setChatID(data.chat_id)
            update_feed(data.messages, chatHistory, setChatHistory, setOptions, setShowInputField, setLoading)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}
export function sendMessageToAPI(chatID, username, message, chatHistory, setChatHistory, setChatID, setOptions, setShowInputField, setLoading) {
    // in order to not have to wait for the api to respond we add the message to the messages array
    // we give it a temporary id and switch it once we receive an actual id from the api
    const last_message = JSON.parse(JSON.stringify(chatHistory.messages.at(-1)))
    let message_type = "RESP";

    const question = last_message.question;
    const end_conversation = last_message.end_conversation;
    let path = null;
    // we want to tell the server whether or not the last message was a containerized message.
    // this way the server know whether or not the current user message should also be containerized
    if (last_message.path) {
        path = last_message.path;
    }

    setOptions([])
    setShowInputField(false)
    if (end_conversation === false) {
        setLoading(true)
    }
    let last_messages = JSON.parse(JSON.stringify(chatHistory));
    let message_content;
    let answer_options = null;
    if (message.is_multiple_choice) {
        message_content = message.input.name
        answer_options = [message.input.id]
    } else {
        message_content = message.input
    }

    const body = {
        "chat_id": chatID,
        "user": username,
        "question": question,
        "message_type": message_type,
        "message": {
            "id": "temp_id",
            "content": message_content,
            "role": "user",
            "created_at": null
        },
        "has_multiple_choice": message.is_multiple_choice,
        "has_text_input": !message.is_multiple_choice,
        "answer_options": answer_options,
        "end_conversation": end_conversation,
        "path": path,
    }
    last_messages.messages.push(body)
    setChatHistory(last_messages)

    const url = serverURL + 'api/v2/messages/';
    let authtoken_server = localStorage.getItem("auth_token");

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authtoken_server}`
        },
        body: JSON.stringify(body),
    }).then(response => response.json())
        .then((data) => {
            setChatID(data.chat_id)
            data.messages.sort((a, b) => new Date(a.message.created_at) - new Date(b.message.created_at))
            update_feed(data.messages, last_messages, setChatHistory, setOptions, setShowInputField, setLoading)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

function update_feed(messages, last_messages, setChatHistory, setOptions, setShowInputField, setLoading, first_assistant_message = true) {
    // added the new messages to the last_messages array. since there can be multiple new messages we add all and remove
    // the temporary one we created

    // create a deep copy of the last messages and messages array
    let new_messages = JSON.parse(JSON.stringify(last_messages));
    messages = JSON.parse(JSON.stringify(messages));
    if (messages[0].message_type === "RESP") {
        new_messages.messages.pop();
    }

    new_messages.messages.push(...[messages[0]])
    setChatHistory(new_messages)

    if (messages[0].message.role === "user") {
        setShowInputField(false)
        setOptions([])
        setLoading(true)
        if (messages[0].end_conversation) {
            setLoading(false)
        }
    }
    if (messages[0].message.role === "assistant" && messages[0].message_type === "QUEST" || messages[0].message_type === "INITQ") {
        setLoading(false)
        if (messages[0].has_multiple_choice) {
            const answer_options = messages[0].answer_options
            setOptions(answer_options)
        } else {
            setOptions([])
        }
        if (messages[0].has_text_input) {
            setShowInputField(true)
        } else {
            setShowInputField(false)
        }
    }

    messages.shift()
    if (messages.length > 0) {
        if (messages[0].message.role === "assistant" && messages[0].message_type !== "INITQ") {
            // if this is the first assistant message we don't wait, because we ant to first message to be displayed immediately
            if (first_assistant_message) {
                update_feed(messages, new_messages, setChatHistory, setOptions, setShowInputField, setLoading, false)
            } else {
                // if we are not at the first message we wait for the user to read the message before sending the next one
                const wait_time = messages[0].message.content.length * 20
                let max = Math.max(wait_time, 2000);
                setTimeout(() => {
                    update_feed(messages, new_messages, setChatHistory, setOptions, setShowInputField, setLoading, false)
                }, max);
            }
        } else {
            update_feed(messages, new_messages, setChatHistory, setOptions, setShowInputField, setLoading)
        }
    }
}
