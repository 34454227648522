import React from 'react';
import './styles/chat_input.css';

const McOptions = ({ options, optionCallback }) => {

    return (
        <div className='chat-mc-input'>
            {options.map((option, index) => {
                return (
                    <button className='mc-option' key={index} onClick={() => optionCallback({ name: option.name, id: option.id })}>{option.name}</button>
                );
            }
            )}
        </div>
    );
}


export default McOptions;