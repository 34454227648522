import './DashboardPage.css';
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, once, onValue, query, equalTo, orderByKey, orderByChild } from "firebase/database";

import Navbar from '../components/NavBar';
import ProjectPage from './ProjectPage';

import app from '../initFirebase';

function DashboardPage() {
    const [user, setUser] = useState(null); // This will be used to store the user object from Firebase
    const [listOfProjects, setListOfProjects] = useState([]);
    const [projectID, setProjectID] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [projectComments, setProjectComments] = useState("");
    const [admin, setAdmin] = useState(false);

    const auth = getAuth();
    const db = getDatabase(app);

    useEffect(() => {
        // Check if the user is logged in
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                user.getIdTokenResult().then((idTokenResult) => {
                    const client = idTokenResult.claims.client;
                    const role = idTokenResult.claims.role;

                    setAdmin(idTokenResult.claims.role === "admin");
                    let projects = [];
                    if (role === "admin") {
                        const projectsRef = ref(db, 'projects/');
                        onValue(projectsRef, (snapshot) => {
                            snapshot.forEach((child) => {
                                const project = child.val();
                                projects.push(project);
                            });
                            setListOfProjects([...projects]);
                        });
                        return;
                    } else if (client !== undefined) {
                        const clientRef = ref(db, 'clients/' + client + '/projects');
                        onValue(clientRef, (snapshot) => {
                            const projectIDs = snapshot.val();
                            for (const projectID in projectIDs) {
                                const projectRef = ref(db, 'projects/' + projectID);
                                onValue(projectRef, (snapshot) => {
                                    const project = snapshot.val();
                                    projects.push(project);
                                    setListOfProjects([...projects]);
                                });
                            }
                        });
                    }
                    return;

                });
            } else {
                window.location.href = "/";
            }
        });
    }, [])

    // useEffect(() => {
    //     // Fetch projects from backend
    //     let projects = [];
    //     listOfProjectIDs.forEach((projectID) => {
    //         const projectsRef = query(ref(db, 'projects/'), orderByKey(), equalTo(projectID));
    //         onValue(projectsRef, (snapshot) => {
    //             const project = snapshot.val();
    //             projects.push(project[String(projectID)]);
    //             console.log(projects);
    //         });
    //     });
    //     setListOfProjects(projects);
    // }, [listOfProjectIDs]);

    function hideProjectsPanel(event, project) {
        const id = event.currentTarget.id.split("_")[1];
        const name = event.currentTarget.querySelector("h3").innerText;
        const dashboardContent = document.getElementById("dashboard-content");
        // Start the transition by changing opacity
        dashboardContent.style.opacity = '0';

        // Listen for the transition to end before completely hiding the content
        dashboardContent.addEventListener('transitionend', function handler(e) {
            if (e.propertyName === "opacity") { // Check if the opacity transition has finished
                dashboardContent.style.display = 'none'; // Make the dashboard content disappear
                // Now you can load and show your project details
                // For example, you might want to call another function here to display the project:
                // displayProjectDetails(projectId);
                dashboardContent.removeEventListener('transitionend', handler); // Clean up the event listener
                setProjectID(id);
                setProjectName(name);
                setProjectComments(project.comment);
                document.getElementById("project-content").style.display = 'flex';
            }
        });
    }

    function showProjectsPanel() {
        const dashboardContent = document.getElementById("dashboard-content");
        dashboardContent.style.display = 'flex';
        dashboardContent.style.opacity = '1';
    }

    return (
        <div className="App">
            <Navbar page={"dashboard"} />
            {user === null ? <></>
                :
                <div className="dashboard-page-content-container" id='dashboard-content'>
                    <h1 >
                        {admin ? "Admin Page." : "Your Projects with us."}
                    </h1>
                    <div className="projects-container" id='projects-container'>
                        {/* the list of projects should be displayed in a grid format with 3 columns and potentially infinite rows*/}
                        {listOfProjects.map((project, index) => {
                            return (
                                <div key={index} className="project-card" id={"project_" + project.id} onClick={(e) => hideProjectsPanel(e, project)}>
                                    <div>
                                        <h3>{project.name}</h3>
                                        <p>{project.description}</p>
                                    </div>
                                    <div className='project-card-client-tag' style={{ paddingRight: admin ? "15px" : "5px" }}>
                                        <div className='project-card-client-tag-circle' />
                                        {admin ? <h4>{project.owners}</h4> : null}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
            <ProjectPage projectID={projectID} projectName={projectName} projectComments={projectComments} showDashboard={showProjectsPanel} user={user} />
        </div>
    );
}

export default DashboardPage;
