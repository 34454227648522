import './MainPage.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Navbar from '../components/NavBar';

function MainPage() {

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      window.location.href = "/dashboard";
    }
  });

  return (
    <div className="App">
      <Navbar page={"main"} />
      <div className="main-page-content-container">
        <h1 >Welcome to faltstein's Development and Prototyping Environment</h1>

        <a href="/login">
          <button className="main-page-button">Log In</button>
        </a>
      </div>
    </div>
  );
}

export default MainPage;
