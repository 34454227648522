import React, { useRef, useEffect } from 'react';
import './styles/chat_input.css';

const InputField = ({ inputEventHandler }) => {

    // Creating a ref to attach to the messages container
    const messagesEndRef = useRef(null);

    // Effect to run each time the messages array changes
    useEffect(() => {
        // Scroll to the bottom of the list whenever messages change
        if (messagesEndRef.current) {
            messagesEndRef.current.select();
        }
    }, [inputEventHandler]); // Dependency array includes messages, so it runs every time messages change

    function test(e) {
        console.log(e.target.scrollHeight);
        e.target.style.height = '10px';
        let scrollHeight = e.target.scrollHeight - 20;
        e.target.style.height = `${scrollHeight}px`;
    }

    return (
        <textarea rows={1} onChange={test} ref={messagesEndRef} type='text' id='chat_input' onKeyDown={inputEventHandler} className='text-input' placeholder='Schreib etwas...' />
    );
}

export default InputField;