// this component serves as the container for the chat messages

import React, { Component } from 'react';

import './styles/message_feed.css';
import Lottie from 'react-lottie';
import animationData from './chat_load_animation.json'
import assistant_logo from '../../../images/myndpaar_logo.png';

class LoadingAnimnation extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <div className='message' style={{ display: "flex", flexDirection: "row", paddingTop: 0, paddingBottom: 0 }}>
                <img className='message-sender-logo' style={{ alignSelf: "center" }} src={assistant_logo}></img>
                <div>
                    <Lottie
                        options={defaultOptions}
                        height={"50px"}
                        width={"50px"}
                    />
                </div>
            </div>
        );
    }
}

export default LoadingAnimnation;
