import React from 'react';
import './styles/chat_input.css';
import SendLogo from '../../../images/send.png';
const SendButton = ({ sendBTNEventHandler }) => {

    return (
        <button className='send-button' onClick={sendBTNEventHandler}>
            <img className='send-logo' src={SendLogo}></img>
        </button>
    );
}

export default SendButton;