// this component serves as the container for the chat messages

import React, { Component, useRef, useEffect } from 'react';

import './styles/message_feed.css';
import Message from './message';
import LoadingAnimnation from './loading_anm';
import assistant_logo from '../../../images/myndpaar_logo.png';
import playbutton from '../../../images/play_btn.svg';
import search_myndpaar from '../../../images/search_myndpaar.png';

const user_logo = null

const MessageFeed = ({ chatHistory, height, loading, preview }) => {

    // Creating a ref to attach to the messages container
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory, loading]);

    // Group messages by activity and separate undefined activity messages
    const { groupedMessages, undefinedMessages } = chatHistory.messages.reduce((result, message) => {
        let activityId = undefined;
        if (message.path) {
            activityId = message.path.path_id;
        }
        if (activityId) {
            if (!result.groupedMessages[activityId]) {
                result.groupedMessages[activityId] = [];
            }
            result.groupedMessages[activityId].push(message);
        } else {
            result.undefinedMessages.push(message);
        }
        return result;
    }, { groupedMessages: {}, undefinedMessages: [] });

    return (
        <div className='message-feed' id='message-feed' style={{ height: height }}>
            {preview ?
                chatHistory.messages.map((item) => {
                    if (item.path) {
                        const activityMessages = groupedMessages[item.path.path_id];
                        const header = item.path.name;
                        if (activityMessages) {
                            delete groupedMessages[item.path.path_id]; // To avoid re-rendering the same group
                            return (
                                <div key={item.path.path_id} className='activity-group'>
                                    {header && <div className='activity-header' >
                                        <div>{header}</div>
                                        <div>
                                            <img src={playbutton} alt='play' />
                                            <img src={search_myndpaar} alt='search' />
                                        </div>
                                    </div>}
                                    {activityMessages.map((msg) => {
                                        const logo = msg.message.role === "assistant" ? assistant_logo : user_logo;
                                        return (
                                            <Message
                                                key={msg.message.id}
                                                message_sender_name={msg.message.name}
                                                message_sender_role={msg.message.role}
                                                message_sender_logo={logo}
                                                message_content={msg.message.content}
                                                message_id={msg.message.id}
                                            />
                                        );
                                    })}
                                </div>
                            );
                        }
                    } else {
                        const logo = item.message.role === "assistant" ? assistant_logo : user_logo;
                        return (
                            <Message
                                key={item.message.id}
                                message_sender_name={item.message.name}
                                message_sender_role={item.message.role}
                                message_sender_logo={logo}
                                message_content={item.message.content}
                                message_id={item.message.id}
                            />
                        );
                    }
                })
                :
                chatHistory === undefined ? null :
                    chatHistory.messages.map((item) => {
                        // create the logo of the message sender
                        const logo = item.message.role === "assistant" ? assistant_logo : user_logo;
                        return <Message
                            key={item.message.id}
                            message_sender_name={item.message.name}
                            message_sender_role={item.message.role}
                            message_sender_logo={logo}
                            message_content={item.message.content}
                            message_id={item.message.id} />
                    })}
            <div ref={messagesEndRef} />
            {loading ? <LoadingAnimnation /> : null}
        </div>
    );

}

export default MessageFeed;
